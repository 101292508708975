import React, { useState } from "react"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
import { HeroSection } from "../components/landingPage/HeroSection"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CodeExample from "../components/home/CodeExample"
import { LandingPageFeatures } from "../components/landingPage/LandingPageFeatures"
import { CompatibleLibraries } from "../components/landingPage/CompatibleLibraries"

const useStyles = makeStyles(theme => ({
  mainArea: {
    marginBottom: "30px",
  },
  h2: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: "50px",
    marginBottom: "20px",
    fontSize: "1.75rem",
    fontWeight: "600",
  },
  list: {
    listStyle: "circle",
  },
  logo: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "50px",
    },
  },
}))

const YamlLandingPage = ({ data, location }) => {
  const classes = useStyles()
  const [isFirstTranslated, setFirstTranslated] = useState(false)

  return (
    <Layout>
      <HeroSection>
        <Hero
          title="Translate Your Ruby on Rails Web App"
          subtitle="Works with different i18n libraries"
          callToAction="Start translating"
          source="Signup HeroRails"
        />
      </HeroSection>
      <Container maxWidth="lg" className={classes.mainArea}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2}>
              Translation of i18n YAML Locales
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              Use Simpleen to machine translate your Ruby on Rails i18n YAML
              locales from your software project. It can be customized with a
              glossary and either used directly or via the API.
              <br />
              <br />
              Simply copy/paste your YAML data from one language into our In-App
              Translate and receive instant results. Simpleen manages the
              correct handling of the format, the <strong>interpolation</strong>
              , the context and the <strong>glossary</strong>.
              <br />
              <br />
              Save time and money in your multilingual projects by using
              Simpleen.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <GatsbyImage
              image={data.yaml.childImageSharp.gatsbyImageData}
              alt="Ruby on Rails i18n YAML Logo"
              className={classes.logo}
              // Above the fold optimization
              fadeIn={false}
              loading="eager"
            />
          </Grid>
          <Grid item xs={12}>
            <CodeExample
              codeFirst={data.snippetExample}
              codeSecond={data.snippetExampleTranslated}
              langCodeFirst="en"
              langCodeSecond="de"
              isTranslated={isFirstTranslated}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ textAlign: "center" }}>
              <Button
                style={{ marginLeft: "auto", marginRight: "auto" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  window &&
                    window.plausible &&
                    window.plausible(
                      isFirstTranslated
                        ? "Clicked Revert Example"
                        : "Clicked Translate Example"
                    )
                  setFirstTranslated(!isFirstTranslated)
                }}
              >
                {isFirstTranslated ? "Revert" : "Translate Example"}
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <CompatibleLibraries currentPage="i18nRubyRails" />
      <LandingPageFeatures source="Signup ButtonRails" />
      <Seo
        general={{
          title: "Translate Ruby on Rails YAML locales",
          description: "Machine translation of your i18n software project",
          path: location.pathname,
          image: data.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const vueLP3Query = graphql`
  query i18nYamlQuery {
    twitter: file(relativePath: { eq: "seo/i18nRubyRailsSEO.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
    yaml: file(relativePath: { eq: "logos/i18nRubyRails.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          quality: 95
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
    }
    snippetExample: markdownRemark(frontmatter: { slug: { eq: "/yamlCode" } }) {
      html
    }
    snippetExampleTranslated: markdownRemark(
      frontmatter: { slug: { eq: "/yamlCodeTranslated" } }
    ) {
      html
    }
  }
`

export default YamlLandingPage
